import React, { useEffect } from 'react'
import ChatsMessage from './ChatsMessage'
import {useDispatch, useSelector} from "react-redux"
import { addMessage } from '../utils/LiveChatsSlice';
import { generateRandomName, generateRandomComment} from '../utils/helper';

const LiveChats = () => {

    const dispatch = useDispatch();
    const LiveChatMsgs = useSelector((store)=>store.livechatslice.messages);

    useEffect(()=>{
        
     const i= setInterval(()=>{
        dispatch(addMessage({
            name: generateRandomName(),
            text: generateRandomComment(),
        }))

    },800);

        return ()=>clearInterval(i);
    });


  return (
      <>
    <div className='flex flex-col-reverse'> 
        { (LiveChatMsgs.length>0) && ( <Chatshigherorder info={LiveChatMsgs[LiveChatMsgs.length - 1]}/>) }
    </div>
    <div>
        {LiveChatMsgs.slice(0, -1).map((ele,idx)=><ChatsMessage key={idx} name={ele.name} text={ele.text}/>) } 
    </div>
    </>
  )
}

export default LiveChats;


export const Chatshigherorder=({info})=>{
    return(
        <>
            <div className='border border-green-500'>
                <ChatsMessage key={14251} name={info.name} text={info.text}/>
            </div>
        
        </>
    )
};
// above higherOrder function ,added at the top , how i show this at the front?? 
// for this i must know how my all chats showning 