import { useEffect } from "react";
import { YT_SEARCH_URL_API } from "./constants";
import { useDispatch } from 'react-redux';
import { addsrchcontent } from "./SrchAPIdataSlice";

const useSearchAPICall = (srchdataquery) => {

    const dispatch = useDispatch();

    useEffect(()=>{
          Srch_API_call();
      },[srchdataquery])  // eslint-disable-line react-hooks/exhaustive-deps

    const Srch_API_call=async()=>{
        if(srchdataquery==="") return;
        try{
            const temp = await fetch(`${YT_SEARCH_URL_API}${srchdataquery}`);
            console.log(`${YT_SEARCH_URL_API}${srchdataquery}`);
            if(temp.ok){
                const json = await temp.json();
                dispatch(addsrchcontent(json?.items));
            }
            else {
                console.log("Failed SrchAPI not OK");
                }
        }catch (error) {
            console.log('SrchDataAPI "catch" Error: ' + error.message);
        }
    }

    return ;
}
export default useSearchAPICall;



