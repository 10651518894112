import React, { useContext } from 'react'
import Comments from './Comments';
import ThemeContext from '../utils/ThemeContext';

const CommentsData =[
  {
    name : "Himanshu",
    text : "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    replies : [
      {
        name : "Himanshu",
        text : "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        replies : []
      }
    ]
  },
  {
    name : "Himanshu",
    text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
    replies: [{
      name : "Himanshu",
      text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
      replies: []
    },
    {
      name : "Himanshu",
      text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
      replies: [{
        name : "Himanshu",
        text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
        replies: [{
          name : "Himanshu",
          text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
          replies: [{
            name : "Himanshu",
            text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
            replies: [{
              name : "Himanshu",
              text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
              replies: []
            }]
          }]
        }]
      }]
    }
  ]
  },
  {
    name : "Himanshu",
    text : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque, esse.",
    replies: []
  }
]

const CommentsList = ({comments})=>{
  return comments.map((element,idx)=>(
    <>
      <Comments key={idx} data={element}/>
      <div className='pt-0 -top-2  border-l-2 border-zinc-300'>
        <div className='ml-9'>
          {/* doing recursion => replies also list of comments */}
          <CommentsList key={idx} comments={element.replies}/> {/**i think here key requirs */}
        </div>
      </div>
    </>
  
  
  ));
}


const CommentsContainer = () => {
  const {theme} = useContext(ThemeContext);
  const {dark_Theme} =theme;
  return (
    <div className={`my-5 mx-3 w-[953px] text-black ${ dark_Theme?'bg-black ':'bg-white'}`}>
        <h1 className={`mb-3 font-bold text-2xl ${dark_Theme?'text-white':'text-black'}`}>Comments:</h1>
        <div>
         <CommentsList comments={CommentsData}/>
        </div>
        
    </div>
  )
}

export default CommentsContainer;