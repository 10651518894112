import React from 'react'

const Videocard = ({info}) => {
    const {snippet,statistics} = info;
    const {channelTitle,title,thumbnails} = snippet;
    
  return (
      
    <div className='ml-4 mb-10'>
        <div className='bg-gray-500 w-[400px] h-[230px] rounded-xl'>
            <img className='w-[400px] h-[230px] rounded-xl' alt={title} src={thumbnails?.high?.url} />
        </div>
        <div className='flex mt-2'>
            <img className='rounded-full w-12 h-12' alt={channelTitle} src={thumbnails?.default?.url} />
            <div className='ml-2'>
                <h2 className='font-bold max-w-[345px] leading-5'>{title}</h2>
                <p className='block text-sm max-w-[345px] mt-1 '>{channelTitle}</p>
                <span className='text-sm relative -top-1'>{statistics?.viewCount} views</span>
                <span className='text-sm relative -top-1'> | {statistics?.likeCount} likes</span>
            </div>
        </div>


    </div>
  )
}

export default Videocard