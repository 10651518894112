import React, { useEffect } from 'react'
import Shimmervideo from './Shimmervideo'
import Videocard from './Videocard'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useAPICall from '../utils/useAPICall'

const VideoContanier = () => {


    // const videocarddata = useAPICall(); //No use of This Custom Hook , after UsingRedux
    useAPICall(); //No use of This Custom Hook , after UsingRedux
    const Reduxvideocarddata = useSelector((store)=>store.apidatastore.apidata); //using Redux ,showing data

    const SrchContentdataRedux = useSelector((store)=>store.srchcontentstore.srchcontent); //using Redux ,showing Srchdata
    useEffect(()=>{
        console.log("update")
    },[Reduxvideocarddata])

    // return isLoading?(<Shimmervideo/>): //Not good , becz it not appending at the end it Just shown in whole App
    return Reduxvideocarddata?.length===0?(<Shimmervideo/>):
    // return (!videocarddata)?(<Shimmervideo/>):
    (
    <div className='flex flex-wrap'>
        {/* {videocarddata[0] && <AddHigherOrder  key={12130}  info={videocarddata[0]}/>} */} {/*HigherOrderComponent*/}
       
        {/* {
            videocarddata.map((ele)=>{
           return (
           <Link key={ele.id} className='cursor-default' to={"/watch?v="+ele.id}>
               <Videocard  info={ele}/></Link>)
          })
        }   */}

        {
         SrchContentdataRedux?.length===0 ?
         (  Reduxvideocarddata.map((innerArray, outerIndex) => {
            return(
                <>
                    {
                        innerArray.map((ele) => {
                        return (
                            <Link key={ele.id} className='cursor-default' to={"/watch?v="+ele.id}>
                                <Videocard  info={ele}/></Link>)
                        })
                    }
                </>)
            })
         ):(
            SrchContentdataRedux.map((innerArray, outerIndex) => {
                return(
                    <>
                        {
                            innerArray.map((ele) => {
                            return (
                                <Link key={ele?.id?.videoId} className='cursor-default' to={"/watch?v="+ele?.id?.videoId}>
                                    <Videocard  info={ele}/></Link>)
                            })
                        }
                    </>)
                })
         )
        } 
    </div>
  )
}

export default VideoContanier;


export const AddHigherOrder=({info})=>{
    return(
        <>
            {/* <div className='cursor-not-allowed after:w-[430px] after:h-[350px] after:bg-black after:content-none after:inline-block'> */}
            <div className='w-[100%] mb-5'>
            <div className='cursor-not-allowed w-[420px] h-[340px] bg-black rounded-lg'>
                <Videocard info={info}/>
            </div>
            </div>
        </>
    )
}



