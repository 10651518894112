import React, { useContext } from 'react'
import ThemeContext from '../utils/ThemeContext';

const Comments = ({data}) => {
    const {name , text} =data;
    const {theme} = useContext(ThemeContext);
    const {dark_Theme} =theme;
  return (
    <>
        <div className={`${dark_Theme?'bg-gray-800 text-white':'bg-zinc-100'} pb-3 mt-2 border-l-2 border-stone-300 rounded-tl-lg`}>
            <div className='font-bold bg-stone-300 text-black inline pl-2 pr-10 pt-[4px] pb-1 rounded-tl-lg'>{name}</div>
            <img className='w-9 h-9 m-1  rounded-full absolute' alt="name" src={`${dark_Theme?'https://i.pinimg.com/474x/81/8a/1b/818a1b89a57c2ee0fb7619b95e11aebd.jpg':'https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg'}`}/>
            <div className='mx-11 mt-1  '>{text}</div>
        </div>
    </>


  )
}

export default Comments;