import { useState } from "react";

const useTheme = () => {

    const [darkMode,setdarkMode] = useState(true);

    const toggleDarkmode=()=>{
      //  return setdarkMode(!darkMode);
       setdarkMode(!darkMode);
    }

  return [darkMode,toggleDarkmode];
}

export default useTheme;