import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import ThemeContext from '../utils/ThemeContext';
import useConnectionStatus from '../utils/useConnectionStatus';



const Body = () => {
  const {theme} = useContext(ThemeContext);
  const {dark_Theme} =theme;

  const statusOnline = useConnectionStatus(false);
  if(!statusOnline) return ( <div className={` w-full h-full ${dark_Theme?'text-white bg-black':'text-black bg-white'}`}> <h1 className='text-center w-full h-full relative top-80 font-bold text-8xl'>Seems OFFLINE!!! <p>Play Tic-Tac-Toe</p></h1> </div>); //EarlyReturn
  return (
    <div className={`flex ${dark_Theme?'text-white bg-black':'text-black bg-white'} top-10 relative overflow-x-hidden overflow-y-hidden`}>
        <Sidebar/>
        <Outlet/>

    </div>
  )
}

export default Body