// This is custom Hook for online offline feature

import { useEffect, useState } from "react";


const useConnectionStatus = ()=>{

    const [statusOnline,setstatusOnline] = useState(true);

    useEffect(()=>{

        function handleEventfunc(status){
            setstatusOnline(status);
        }

        window.addEventListener('online',()=>handleEventfunc(true));
        window.addEventListener('offline',()=>handleEventfunc(false));

        return ()=>{ // removing eventlistener ,function must be same , here reconsillation handles but its a good practise to use this
            window.removeEventListener('online',()=>handleEventfunc(true))
            window.removeEventListener('offline',()=>handleEventfunc(false))
        }

    },[])   


    return ( statusOnline ) //true or false
}

export default useConnectionStatus;


