// const regionCode ="IN";

const API_KEY = process.env.REACT_APP_API_KEY;
const baseURL = 'https://youtube.googleapis.com/youtube/v3/videos';
export const API_URL_VIDEO_CARD =`${baseURL}?part=snippet,contentDetails,statistics&chart=mostPopular&maxResults=50&key=${API_KEY}&pageToken=`;

export const YTSEARCH_SUGGEST_URL_API="http://suggestqueries.google.com/complete/search?client=firefox&ds=yt&q=";
export const YT_SEARCH_URL_API = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&type=video&key=${API_KEY}&q=` 


export const OFFSET_LIVE_CHAT = 70;
export const SUBSCRIPTION_SONG ="/watch?v=AJtDXIazrMo";







// "https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&key=AIzaSyDr_VGZSuPGbGEX6GXCInZnS72BOOXJFTE&q=iphone"




// export const API_URL_VIDEO_CARD = "https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&chart=mostPopular&maxResults=50&regionCode=IN&key="+API_KEY;

// export const YTSEARCH_API="http://suggestqueries.google.com/complete/search?client=firefox&ds=yt&q="+YOUR_SEARCH_TERM;



 // eslint-disable-line react-hooks/exhaustive-deps
  
 //for ignoring alot of comments 
 // eslint-disable-next-line
