import { createSlice } from "@reduxjs/toolkit";

const APIdataSlice = createSlice({
    name : "ApiDataStore",
    initialState:{
        apidata:[],
        flag:true,
    },
    reducers:{
        addapidata: (state,action)=>{
            if (state.flag && state.apidata.length > 0) { // i do this bcz my api called two times simultanously
                state.apidata.shift();
                state.flag=false;
              }
            state.apidata.push(action.payload);
        }
    }
})

export const {addapidata} = APIdataSlice.actions; // plural

export default APIdataSlice.reducer;