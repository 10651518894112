import { createSlice } from "@reduxjs/toolkit";
import { OFFSET_LIVE_CHAT } from "./constants";

const LiveChatsSlice = createSlice({
    name: "livechatmsgs",
    initialState: {
        messages: [],
    },
    reducers: {                                     //plural
        addMessage: (state, action) =>{
            
            state.messages.push(action.payload);
            if(state.messages.length>OFFSET_LIVE_CHAT){
                state.messages.shift();
            }
            // state.messages.splice(OFFSET_LIVE_CHAT,1); //removign from the top
        },


    },
});

export const {addMessage} = LiveChatsSlice.actions; // plural

export default LiveChatsSlice.reducer;