import React from 'react'

const Shimmervideo = () => {
  return (
    <>
    <div className='flex flex-wrap'>
      {Array(30).fill("").map((ele,index)=>(
        <div key={index} className='ml-4 mb-10'>
            <div className='bg-gray-300 w-[400px] h-[230px] rounded-xl'></div>
            <div className='flex mt-2'>
                <div className='rounded-full w-12 h-12 bg-gray-300'/>
                <div className='ml-2 mt-1'>
                    <div className='bg-gray-300 w-72 h-4'></div>
                    <div className='bg-gray-300 w-52 h-4 mt-3'></div>
                </div>
            </div>
        </div>
      ))}
    </div>
    </>
  )
}

export default Shimmervideo