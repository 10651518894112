import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom'
import { addMessage } from '../utils/LiveChatsSlice';
import { closeMenu } from '../utils/SidebarSlice';
import CommentsContainer from './CommentsContainer';
import LiveChats from './LiveChats';
import ThemeContext from '../utils/ThemeContext'

const WatchContent = () => {

  const [livemessage,setlivemessage] = useState("");
  const [searchparam] = useSearchParams();
  const dispatch = useDispatch();

  const handleSubmit = (event)=>{
    event.preventDefault();
    dispatch(addMessage({
      name: "Himanshu",
      text: livemessage,
    }))
    setlivemessage("");
  }
  //by default SidebarHide
  useEffect(()=>{
    dispatch(closeMenu());
    console.log("setclosemenu");
  });

  const onYTVideoLoad=()=>{
    return <>
      <div className='w-[953px] h-[580px] bg-green-500'>YT video Shimmer showing...</div>
    </>
  }

  const {theme} = useContext(ThemeContext);
  const {dark_Theme} =theme;


  return (
    <> <div className='flex mx-auto relative mt-14 z-0 left-12 '>
          <div>
            <iframe 
              width="953px"
              height="580px"
              src={"https://www.youtube.com/embed/"+searchparam.get("v")+"?autoplay=1"} 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
              onload={onYTVideoLoad()}
              >
            </iframe>
            <div>
                <CommentsContainer/>
            </div>
          </div>
          <div className={`p-1 ml-1 w-[300px] h-[580px] ${dark_Theme? 'bg-black text-white' : 'bg-white text-black'} `}> 
            <div className={`border-[2px] ${dark_Theme?'border-white':'border-black'}  text-center rounded-3xl font-bold tracking-widest`}>LIVE CHATS</div>
            <div className={`p-2 ${dark_Theme?'border-white bg-black':'border-black bg-gray-100'} border-2 rounded-lg mt-2 h-[490px] overflow-y-scroll flex flex-col-reverse `}>
                <LiveChats/>
            </div>
            <form className={`h-[40px] border-2 ${dark_Theme?'border-white bg-black':'border-black bg-gray-100'} mt-2 rounded-t-lg`}
              onSubmit={(event)=>{handleSubmit(event)}}
            >
                 <input className='bg-transparent tracking-widest pl-2 mt-[2px] h-[34px] w-[235px] rounded-tl-lg  outline-none' placeholder='message..' type="text" 
                    value={livemessage} onChange={(event)=>{setlivemessage(event.target.value)}}
                 /> 
                 <button className='bg-green-500 text-black ml-1 relative -top-[1px] rounded-lg p-1 active:scale-90'>Send
                    
                 </button>
            </form>
          </div>
          
      </div>

        
    
    </>
  )
}

export default WatchContent