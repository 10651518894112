import React from 'react';

const Buttonlist = () => {
    const btnlist=["All","Music","Himanshu","Akshay Kumar","Rohit","Cricket","Movies","Music","Himanshu","Akshay Kumar","Rohit"];
  
  return (
    <div className='mb-4 ml-2'>
        {
            btnlist.map((element,idx)=>{ 
                return(
                <button key={idx} className='bg-[#262627] p-3 py-1 m-3 mr-2 rounded-lg text-white'>{element}</button>
                );
            })  
        } 
    </div>
  )
}

export default Buttonlist;