import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SUBSCRIPTION_SONG } from '../utils/constants';

const Sidebar = () => {

    const storemessages = useSelector((store)=>store.sidebartoggle.messages);

  return !storemessages
  ? 
  (<div className='shadow-lg flex flex-col h-fit w-fit  top-[4.6rem] fixed' >
      <Link to={"/"}><i className='fas fa-home p-2 mx-4 mt-4'> </i></Link>
      <Link to={SUBSCRIPTION_SONG}><i className='fas fa-play p-2 mx-4 my-2'></i></Link>
      <Link to={"/watch?"}><i className='fas fa-history p-2 mx-4 mb-2'></i></Link>

  </div> )
  : 
  
  (
    <div className=' shadow-lg min-w-max h-screen  top-[4.6rem] fixed z-10 backdrop-blur-lg'>
        <ul className='mx-4 cursor-pointer'>
            <li><i className='fas fa-home pr-2 mb-3 mt-5'></i><Link to={"/"}>Home</Link></li>
            <li><i className='fas fa-play pl-1 pr-2 mb-6'></i>Subscription</li>
        </ul>
        <h1 className='font-extrabold mb-4'>Your channel</h1>
        <ul className='mx-7 cursor-pointer'>
            <li><i className='fas fa-history pr-2  mb-4'></i>History</li>
            <li><i className='fas fa-video pr-2  mb-4'></i>Your videos</li>
            <li><i className='fas fa-film pr-2 mb-4'></i>Watch later</li>
            <li><i className='fas fa-chevron-down pr-2 mb-4'></i>Show more</li>
        </ul>
        <h1 className='font-extrabold my-4'>Subscriptions</h1>
        <ul className='mx-7 cursor-pointer'>
            <li><i className='fas fa-user-tie pr-2 mb-4'/>Abc channel</li>
            <li><i className='fas fa-user-tie pr-2 mb-4'/>Def channel</li>
            <li><i className='fas fa-user-tie pr-2 mb-4'/>Ghi channel</li>
            <li><i className='fas fa-user-tie pr-2 mb-4'/>Xyz channel</li>
            <li><i className='fas fa-chevron-down pr-2 mb-4'></i>Show more</li>
        </ul>
        <h1 className='font-extrabold my-4'>Explore</h1>
        <ul className='mx-7 cursor-pointer'>
            <li> <i className='fas fa-bomb pr-2 mb-4'/>Trending</li>
            <li><i className='fas fa-bag-shopping pr-2 mb-4'/>Shoping</li>
            <li><i className='fas fa-music pr-2 mb-4'/>Music</li>
            <li><i className='fas fa-tower-broadcast pr-2 mb-4'/>Live</li>
        </ul>



    </div>
  )
}

export default Sidebar