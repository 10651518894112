import { createSlice } from "@reduxjs/toolkit";


const SrchAPIdataSlice = createSlice({
    name : "SrchAPIdataStore",
    initialState:{
        srchcontent:[],
    },
    reducers:{
        addsrchcontent: (state,action)=>{
            if(state.srchcontent.length > 0){
                state.srchcontent.pop();
            }
            state.srchcontent.push(action.payload);
        }
    }
});

export const {addsrchcontent} = SrchAPIdataSlice.actions;

export default SrchAPIdataSlice.reducer;