import "./App.css";
import Header from './components/Header';
import Body from './components/Body';
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import MainContanier from "./components/MainContanier";
import WatchContent from "./components/WatchContent";
import { Provider } from "react-redux";
import store from "./utils/store";

import ThemeContext from "./utils/ThemeContext";
import useTheme from "./utils/useTheme";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";

const appRouter = createBrowserRouter([{
    path: '/',
    element: <Body/>,
    children:[
      {
        path:'/',
        element: <MainContanier/>,
        errorElement: <ErrorPage/>
      },
      {
        path:'watch',
        element: <WatchContent/>,
        errorElement: <ErrorPage/>
      }
 
    ]


}
])




function App() {
  const [darkMode,toggleDarkmode] = useTheme(); //CustomHook
  return (
      <Provider store={store}>
       <ThemeContext.Provider 
          value={
            {theme:{
              dark_Theme:darkMode, //CustomHook
              toggleTheme:toggleDarkmode,
            }}
          }
        >
          <Header/>
          <RouterProvider router={appRouter}/>
          <Footer/>
        </ThemeContext.Provider>
      </Provider>
  );
}

export default App;
