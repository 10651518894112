import { createSlice } from "@reduxjs/toolkit";

const MemoSrchSlice= createSlice({

    name: "memoSrch",
    initialState: {
      
    },
    reducers:{
        memoSrchResults(state,action){
            // state = {...action.payload , ...state}; //tricky
            state = Object.assign(state, action.payload);
        },
    },
});

export const {memoSrchResults} = MemoSrchSlice.actions;

export default MemoSrchSlice.reducer;