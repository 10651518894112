import React, { useContext } from 'react'
import ThemeContext from '../utils/ThemeContext'

const ChatsMessage = ({name,text}) => {
  const {theme} = useContext(ThemeContext);
  const {dark_Theme} =theme;
  return (
   <>
    <div className={`flex pb-1 mb-1 shadow-sm ${dark_Theme?'bg-gray-800':'bg-gray-100'}`}>
        <img className='w-9 h-9 rounded-full ' alt="name" src={`${dark_Theme?'https://i.pinimg.com/474x/81/8a/1b/818a1b89a57c2ee0fb7619b95e11aebd.jpg':'https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg'}`}/>
        <div className='font-bold mr-3 mt-[6px]'>{name} <span className='font-normal'>{text}</span></div>
    </div>
   </>
  )
}

export default ChatsMessage