import { useRouteError } from "react-router-dom"; // this is a hook bcz starts with use (normal function) : its used to get Error Info


function ErrorPage(){
    const error = useRouteError(); // we can Destruct here also

    return (
        <>
            <div className="w-screen h-screen flex justify-center  my-40">
                <div className="text-justify">
                    <h1>Oh noh !!</h1>
                    <h2>Seems something NOT Correct</h2>
                    <h1 className="mt-12 text-lg text-red-500"><span className="font-bold mr-5 text-white ">Message:</span>{error.message}</h1>
                    <h3 className="my-2"><span className="font-bold mr-5">Status:</span>{error.status + " : " + error.statusText}</h3>
                    <p className="my-2"><span className="font-bold mr-5">Details of Error is:</span>{error.data}</p>
                </div>
            </div>
        </>
    )
}

export default ErrorPage;