import {configureStore} from "@reduxjs/toolkit";
import APIdataSlice from "./APIdataSlice";
import LiveChatsSlice from "./LiveChatsSlice";
import MemoSrchSlice from "./MemoSrchSlice";
import OriginSlice from "./OriginSlice";
import SidebarSlice from "./SidebarSlice";
import SrchAPIdataSlice from "./SrchAPIdataSlice";

const store = configureStore({

    reducer: {
        livechatslice : LiveChatsSlice,
        sidebartoggle : SidebarSlice,
        memosrch : MemoSrchSlice,
        apidatastore : APIdataSlice,
        srchcontentstore : SrchAPIdataSlice,
        SetOrigin : OriginSlice,
    }
});

export default store;
