import { createSlice } from "@reduxjs/toolkit";

const SidebarSlice = createSlice({
    name: "SidebarToggle",
    initialState:{
        messages:true,
    },
    reducers:{
        toggleMenu: (state)=>{
            state.messages=!state.messages;
        },
        closeMenu: (state) => {
            state.messages = false;
        },
    },
});

export const {toggleMenu,closeMenu} = SidebarSlice.actions;

export default SidebarSlice.reducer;