import React from 'react'
import Buttonlist from './Buttonlist'
import VideoContanier from './VideoContanier'


const MainContanier = () => {
  return (
    <div className='mt-8 relative left-[10.5rem]'>
        <Buttonlist/>
        <VideoContanier/>
    </div>
  )
}

export default MainContanier;