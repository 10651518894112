import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddOrigin } from '../utils/OriginSlice'
import { toggleMenu } from '../utils/SidebarSlice'
import ThemeContext from '../utils/ThemeContext'
import useConnectionStatus from '../utils/useConnectionStatus'
import useSearchAPICall from '../utils/useSearchAPICall'
import useSuggSrchAPICall from '../utils/useSuggSrchAPICall'
import { useSelector } from 'react-redux';




const Header = () => {

  const dispatch = useDispatch();
  const HandleMenu=()=>{
    dispatch(toggleMenu());
  }
  const [showSugg,setshowSugg] =useState(false); //showing suggestions
  const [querydata, setquerydata] = useState("");  // user Search Query storing 
  const handleSrch=(event)=>{
    event.preventDefault();
    
    setsrchdataquery(querydata); //searchapi
    setquerydata("");

  }
  const [origin,setorigin] = useState("");
  const handleOrigin=(event)=>{
    event.preventDefault();
    dispatch(AddOrigin(origin));
  }
  
  const srchSugg = useSuggSrchAPICall(querydata); //getting api srchSugg
  // now user click on srchSugg data , so store this in setsrchdata and call api TO show Videos
  
  const [srchdataquery,setsrchdataquery] = useState("");
  useSearchAPICall(srchdataquery); // getting Srch Content
  


const {theme}=useContext(ThemeContext);
const {dark_Theme,toggleTheme}=theme; //destructing theme

const statusOnline = useConnectionStatus(false); 

  return (
    <div className={`${dark_Theme?'text-white bg-black':'text-black bg-white'} fixed top-0 left-0 w-full z-10`}>
    <div className='grid grid-flow-col p-3 relative top-2 h-16 shadow-lg backdrop-blur-sm'>
            <div className='flex px-4 col-span-1'>
                <i className='fas fa-bars text-2xl h-5 cursor-pointer'onClick={()=>HandleMenu()} ></i>

                 <a href="/"><img className='h-12 mx-4 -my-2 rounded-2xl cursor-pointer ' alt="youtubeicon" src="https://i.insider.com/59a59a8d79bbfd1d008b601a?width=1200&format=jpeg"  /></a>
            </div>
            <div><i className={`fas fa-circle text-base my-1  ${statusOnline ?'text-green-500':'text-red-500'}`}/></div>
            
            <div className='col-span-10 px-48 -my-2'>
              <form 
              onSubmit={(event)=>handleSrch(event)}
              >
                <input className={`w-3/4 border outline-none border-gray-400  rounded-l-full p-2 px-4 ${dark_Theme?'bg-black':'bg-white'}`} type="text" placeholder='Search'
                    value={querydata} onChange={(event)=>setquerydata(event.target.value)} onFocus={()=>setshowSugg(true)} onBlur={()=>setshowSugg(false)}
                />
                <button className={`border border-gray-400 rounded-r-full w-16  p-2 ${dark_Theme?'bg-gray-700':'bg-gray-100'}`}><i className='fas fa-search '></i></button>
              </form>
              {showSugg && srchSugg?.length!==0 &&
                <div className='bg-gray-100 text-black w-3/4 mt-1  relative z-10 rounded-lg shadow-2xl border border-gray-50'>
                  <ul className='my-2' >
                    { 
                      srchSugg.map((ele)=>{return( <li key={ele}  className='shadow-sm hover:bg-gray-200 pl-4  cursor-default'> <i className='fas fa-search mr-4 py-2' ></i>{ele}</li>)}) 
                    }
                  </ul>
                </div>
              }
            </div>
            <div className='mr-10'>
                <form onSubmit={handleOrigin}>
                    <input className='w-8 h-8 cursor-default bg-transparent outline-none text-lg text-gray-400' value={origin} onChange={(event)=>setorigin(event.target.value)} placeholder={useSelector((store)=>store.SetOrigin.Origin)} type="text" />
                </form>
             </div>

            <div className='w-12 h-fit text-center text-violet-600 '>
              <button onClick={toggleTheme}> {dark_Theme ? <i className="fas fa-moon fa-2x"></i> : <i className="far fa-smile fa-2x"></i>}</button>
            </div>
            
            <div className='col-span-1 text-2xl'>
                    <i className="far fa-bell h-10 mx-4 mr-6 "></i>
                    <i className="far fa-user h-20 "></i>
            </div>
    </div>
    </div>
  )
}

export default Header