import { useEffect, useState } from 'react'
import {useDispatch, useSelector } from 'react-redux'

import { YTSEARCH_SUGGEST_URL_API  } from '../utils/constants'
import { memoSrchResults } from '../utils/MemoSrchSlice'



const useSuggSrchAPICall = (querydata) => {
   const dispatch = useDispatch();

   const [srchSugg,setsrchSugg] = useState([]); //storing apicalled data


  //SrchMemo subscribing
  const srchMemo = useSelector((store)=>store.memosrch);

  useEffect(()=>{
    
    const i=setTimeout(() => {  //applying Debouncing Concept Here
      if(srchMemo[querydata]){ 
        setsrchSugg(srchMemo[querydata]);
      }
      else{
      Sugg_API_call();
      }
    }, 200);
    return ()=>clearInterval(i);
  },[querydata])  // eslint-disable-line react-hooks/exhaustive-deps
  
  const Sugg_API_call=async()=>{
    try{
        const temp = await fetch(`${YTSEARCH_SUGGEST_URL_API}${querydata}`,{ mode: 'no-cors'});
        console.log(`${YTSEARCH_SUGGEST_URL_API}${querydata}`);
        // console.log(temp);
        if(temp.ok){
            // const json = await temp.json();
            // setsrchSugg(json[1]);
            // console.log(json[1]);
        }
        else {
            console.log("Failed SrchSuggAPI not OK");
          }
    }catch (error) {
        console.log('SrchSuggAPI "catch" Error: ' + error.message);
    }

    setsrchSugg([]);
    setsrchSugg([
    "hima","hey","make","yadav"
    ]);

    dispatch(memoSrchResults({
    // querydata: json[1], //here which one is pass as state or action.payload means how it stores into slice or inside slice what is state??
    // [querydata]: json[1], why use [] ?????
    }));

}
  
  
  return srchSugg;
}


export default useSuggSrchAPICall;


// const YTSEARCH_SUGGEST_URL_API="http://suggestqueries.google.com/complete/search?client=firefox&ds=yt&q=";
// const srchdata = "hima"
// fetch(`${YTSEARCH_SUGGEST_URL_API}${srchdata}`,{ mode: 'no-cors'});