import {createContext} from "react";

//custom hook cannot be called at Top , Hook only be called in a React function component
// const [darkMode,toggleTheme] =useTheme(); //custom Hook

const ThemeContext = createContext();

ThemeContext.displayName = "Theme_Context";

export default ThemeContext;
