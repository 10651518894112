import React,{useContext} from 'react'
import ThemeContext from '../utils/ThemeContext'

const Footer = () => {
    const {theme} = useContext(ThemeContext);
    const {dark_Theme} =theme;
  return (
    <div className={`w-screen h-16 flex items-center relative mt-10 justify-center shadow-md ${dark_Theme?'text-white bg-black':'text-black bg-white'}`}>
            <span className='font-mono text-xl tracking-[0.8rem] '>HIMANSHU</span><span className='mt-2 text-[0.5rem]'>@2023</span>
    </div>
  )
}

export default Footer