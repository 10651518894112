import { API_URL_VIDEO_CARD } from '../utils/constants'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addapidata } from './APIdataSlice';
import { useSelector } from 'react-redux';


const useAPICall = () => {
    const dispatch = useDispatch();
    const regionCode = useSelector((store)=>store.SetOrigin.Origin);

    // const [videocarddata,setvideocarddata] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [nextpage, setnextPage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    
    const oururl = `${API_URL_VIDEO_CARD}${nextpage}+&regionCode=${regionCode}`;
   
    const handleScroll = () => { 
        // set delay here to persist inconsistency in apicall
        if ((window.innerHeight + window.scrollY >=  document.body.scrollHeight) ) {
            if (!isLoading && hasMore) {
                setPage((prevPage) => prevPage + 1);
                console.log("handlescroll HIT: "+ page);  
            }    
        }  
    };
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps
    

    useEffect(()=>{
        apivideocard(oururl);
        // console.log(regionCode+"regionCode");

    },[page, hasMore])  // eslint-disable-line react-hooks/exhaustive-deps

    const apivideocard= async(oururl)=>{
        if (!hasMore || nextpage===undefined) return; // Don't fetch if there's no more data to load
        try{
            setIsLoading(true);
            const temp = await fetch(oururl);
            if (temp.ok) {
                const json = await temp.json();
                if (json?.items.length > 0) {
                    // setvideocarddata(json?.items);
                    setnextPage(json?.nextPageToken);
                    dispatch(addapidata(json?.items)); //why this runs two TIMES??
                    // setvideocarddata((prevdata)=>[...prevdata, ...json?.items]);
                } else {
                        setHasMore(false); // No more data available
                    }
            }else {
                console.log("Failed API not OK");
              }
        } catch (error) {
            console.log('API "catch" Error: ' + error.message);
        }finally {
            setIsLoading(false);
        }
    }    

 return isLoading;
}

export default useAPICall;


// eslint-disable-next-line
{/* Direct copy paste it in VideoContanier if i not want to use CustomHook for apicall

import { useDispatch } from 'react-redux';
import { addapidata } from '../utils/APIdataSlice';
import { API_URL_VIDEO_CARD } from '../utils/constants'


//removestart //Not able to Detect why at start API calls for TwoTimes
const dispatch = useDispatch();
const [isLoading, setIsLoading] = useState(false);
const [page, setPage] = useState(1);
const [nextpage, setnextPage] = useState('');
const [hasMore, setHasMore] = useState(true);
const oururl = `${API_URL_VIDEO_CARD}${nextpage}`;
useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
},[]);  // eslint-disable-line react-hooks/exhaustive-deps
useEffect(()=>{
    apivideocard(oururl);

},[page, hasMore])  // eslint-disable-line react-hooks/exhaustive-deps

const handleScroll = () => { 
    // set delay here to persist inconsistency in apicall
    if ((window.innerHeight + window.scrollY >=  document.body.scrollHeight) ) {
        if (!isLoading && hasMore) {
            setPage((prevPage) => prevPage + 1);
            console.log("handlescroll HIT: "+ page);  
        }    
    }  
};
const apivideocard= async(oururl)=>{
    if (!hasMore || nextpage===undefined) return; // Don't fetch if there's no more data to load
    try{
        setIsLoading(true);
        const temp = await fetch(oururl);
        if (temp.ok) {
            const json = await temp.json();
            if (json?.items.length > 0) {
                // setvideocarddata(json?.items);
                setnextPage(json?.nextPageToken);
                dispatch(addapidata(json?.items)); //why this runs two TIMES??
                // setvideocarddata((prevdata)=>[...prevdata, ...json?.items]);
            } else {
                    setHasMore(false); // No more data available
                }
        }else {
            console.log("Failed API not OK");
          }
    } catch (error) {
        console.log('API "catch" Error: ' + error.message);
    }finally {
        setIsLoading(false);
    }
}    
//removeEnd

*/}