import { createSlice } from "@reduxjs/toolkit";

const OrginSlice= createSlice({

    name: "origin",
    initialState: {
        Origin: 'IN',
    },
    reducers:{
        AddOrigin(state,action){
            state.Origin=action.payload
        },
    },
});

export const {AddOrigin} = OrginSlice.actions;

export default OrginSlice.reducer;