const randomNames = [
    'Aarav',
    'Aisha',
    'Arjun',
    'Dia',
    'Ishaan',
    'Jiya',
    'Kabir',
    'Leela',
    'Manan',
    'Neha',
    'Ojas',
    'Pari',
    'Rohan',
    'Sara',
    'Veer',
    'Zara',
    'Aanya',
    'Arnav',
    'Dia',
    'Ishan',
    'Jia',
    'Kavita',
    'Laksh',
    'Meera',
    'Naina',
    'Om',
    'Prisha',
    'Rahul',
    'Sanika',
    'Vidya',
    'Yuvan',
    'Aditi',
    'Arun',
    'Divya',
    'Ishita',
    'Kiran',
    'Lavanya',
    'Maya',
    'Nikita',
    'Omkar',
    'Priya',
    'Ria',
    'Sahil',
    'Varsha',
    'Yash',
    'Zain',
    'Aaradhya',
    'Amrit',
    'Chetan',
    'Ishika',
    'Kavya',
    'Lalit',
    'Manvi',
    'Nandini',
  ];

  export function generateRandomName() {
    return randomNames[Math.floor(Math.random() * randomNames.length)];
  }
  

const commentMessages = [
    'Great content!',
    'I love this!',
    'Awesome video!',
    'Very informative!',
    'Fantastic explanation!',
    'Keep up the good work!',
    'This helped me a lot!',
    'I learned something new!',
    'Impressive!',
    'You make it look so easy!',
    'I can\'t believe I didn\'t know this!',
    'Mind-blowing!',
    'You have a new subscriber!',
    'Thanks for sharing!',
    'Incredible stuff!',
    'Absolutely amazing!',
    'You deserve more views!',
    'This is pure gold!',
    'I wish I found this earlier!',
    'You\'re a genius!',
    'I can\'t stop watching your videos!',
    'You are a lifesaver!',
    'So much value!',
    'Bravo!',
    'I\'m inspired!',
    'This is exactly what I needed!',
    'Your explanations are crystal clear!',
  ];
  
 export function generateRandomComment() {
    return commentMessages[Math.floor(Math.random() * commentMessages.length)];
  }
  